import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Popup, Table } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { getGatedComunitiesImNotMember, getMyGatedComunities, leaveGatedCommunity, requestMembershipToGatedCommunity } from 'api-operations/community/member';
import ErrorModal from 'errors/components/ErrorModal';
import ExfluencyLoader from 'helpers/components/loader/ExfluencyLoader';
import ButtonWithPopup from 'dashboards/shared/ButtonWithPopup';
const MyGatedView = styled.div `
  display: flex;
  flex-direction: column;

  h2:not(:first-of-type) {
    margin-top: 1rem;
  }
`;
const Communities = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [errorWindowMessage, setErrorWindowMessage] = useState(null);
    const [myGateCommunities, setMyGateCommunities] = useState(null);
    const [otherGatedCommunities, setOtherGatedCommunities] = useState(null);
    const roles = useSelector(state => state.userProfile.roles);
    const history = useHistory();
    const { t } = useTranslation('common');
    useEffect(() => {
        (async () => {
            await getMyGatedCommunitiesList();
            setIsLoading(false);
        })();
    }, []);
    const getMyGatedCommunitiesList = async () => {
        try {
            const myGatedCommunities = await getMyGatedComunities(false);
            setMyGateCommunities(myGatedCommunities);
        }
        catch (error) {
            //
        }
        try {
            const otherGatedCommunities = await getGatedComunitiesImNotMember();
            setOtherGatedCommunities(otherGatedCommunities);
        }
        catch (error) {
            //
        }
    };
    const goToManageCommunity = (id, name, profile, membershipStatus) => {
        const state = { groupName: name, profile };
        if (membershipStatus === 'MEMBER') {
            history.push({
                pathname: `/community/${id}`,
                state
            });
        }
        else {
            // TODO - alert - no access
        }
    };
    const requestMembership = async (communityId) => {
        try {
            await requestMembershipToGatedCommunity(communityId);
            window.location.reload();
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const handleLeaveGatedCommunity = async (communityId) => {
        try {
            await leaveGatedCommunity(communityId);
            window.location.reload();
        }
        catch (error) {
            toast.error(t('notification.error'));
        }
    };
    const ableToRequest = roles.includes('ROLE_BUYER') || roles.includes('ROLE_ENHANCER')
        || roles.includes('ROLE_REDACTOR') || roles.includes('ROLE_TRUST_MINER') || roles.includes('ROLE_LANGUAGE_MINER');
    if (errorWindowMessage !== null)
        return React.createElement(ErrorModal, { ...errorWindowMessage, onClose: () => window.location.reload() });
    if (isLoading)
        return React.createElement(ExfluencyLoader, null);
    return React.createElement(MyGatedView, null,
        React.createElement("h2", null, t('gatedCommunities.myGc')),
        React.createElement("div", null,
            React.createElement(Table, { celled: true },
                React.createElement(Table.Header, null,
                    React.createElement(Table.Row, null,
                        React.createElement(Table.HeaderCell, null, t('gatedCommunities.name')),
                        React.createElement(Table.HeaderCell, null, t('gatedCommunities.membershipProfile')),
                        React.createElement(Table.HeaderCell, null, t('gatedCommunities.membershipStatus')),
                        React.createElement(Table.HeaderCell, null, t('gatedCommunities.moreOptions')))),
                React.createElement(Table.Body, null, myGateCommunities !== null && myGateCommunities.map(({ gatedCommunityId, gatedCommunityName, profile, membershipStatus }) => React.createElement(Table.Row, { key: gatedCommunityId },
                    React.createElement(Table.Cell, null, gatedCommunityName),
                    React.createElement(Table.Cell, null, t(`membershipProfile.${profile}`)),
                    React.createElement(Table.Cell, null, t(`membershipStatus.${membershipStatus}`)),
                    React.createElement(Table.Cell, null,
                        membershipStatus === "MEMBER" && profile === 'GATED_COMMUNITY_ADMIN' && React.createElement(ButtonWithPopup, { label: t('gatedCommunities.openAdminPanel'), onClick: () => goToManageCommunity(gatedCommunityId, gatedCommunityName, profile, membershipStatus), icon: 'setting', size: 'small' }),
                        profile !== 'GATED_COMMUNITY_ADMIN' && React.createElement(ButtonWithPopup, { label: t('gatedCommunities.leaveCommunity'), onClick: () => handleLeaveGatedCommunity(gatedCommunityId), icon: 'sign-out', size: 'small' }))))))),
        otherGatedCommunities !== null && otherGatedCommunities.length > 0 && React.createElement(React.Fragment, null,
            React.createElement("h2", null, t('gatedCommunities.otherGc')),
            React.createElement("div", null,
                React.createElement(Table, { celled: true },
                    React.createElement(Table.Header, null,
                        React.createElement(Table.Row, null,
                            React.createElement(Table.HeaderCell, null, t('gatedCommunities.name')),
                            React.createElement(Table.HeaderCell, null, t('gatedCommunities.requestMembership')))),
                    React.createElement(Table.Body, null, otherGatedCommunities !== null && otherGatedCommunities.map(({ gatedCommunityId, gatedCommunityName }) => React.createElement(Table.Row, { key: gatedCommunityId },
                        React.createElement(Table.Cell, null, gatedCommunityName),
                        React.createElement(Table.Cell, null,
                            React.createElement(Popup, { content: ableToRequest ? t('gatedCommunities.requestMembership') : t('gatedCommunities.toRequestMessage'), trigger: (React.createElement("span", null,
                                    React.createElement(Button, { disabled: !ableToRequest, size: 'small', onClick: () => requestMembership(gatedCommunityId), icon: 'add user' }))) })))))))));
};
export default Communities;
