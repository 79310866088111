/* eslint-disable no-console */
import { searchAsset, trackSearch } from "api-operations/editor/editor";
import IconButton from "editorNextGen/components/IconButton";
import SearchBar from "./SearchBar";
import SubAssetElement from "editorNextGen/SubAssetElement";
import { ancestorOfType, consumeEvent } from "editorNextGen/utils";
import i18next from "i18next";
import EditorModule from "../EditorModule";
import { HTTPLogger } from "logger/HTTPLoggerStatic";
import { IconNgBuilder } from "editorNextGen/icons";
import { magnitudeSvg } from "editorNextGen/icons/magnituge";
import "./style.scss";
import UniversalTagElement from "editorNextGen/UniversalTag";
import TerminologyElement from "editorNextGen/TerminologyElement";
const SEARCH_LIMIT = 5000;
export default class SearchModule extends EditorModule {
    constructor(editor) {
        super(editor);
        this.moduleId = "SearchModule";
        this.searchButton = new IconButton("icon search", i18next.t("editorHeader.search"));
        this.searchPhrase = "";
        this.searchResult = null;
        this.searchBar = new SearchBar(this);
        this.editor.header.headerRow.insertBefore(this.searchBar, this.editor.header.toolBar);
        new IconNgBuilder(magnitudeSvg, this.editor.header.toolBar)
            .setOnClick(() => { this.searchMode = !this.searchMode; })
            .setToggable(true).build();
        this.markMap = new Map();
        this.markActive = { order: -1, position: -1 };
    }
    unload() {
        this.searchBar.remove();
        this.searchButton.remove();
    }
    assetsCreated(allAssetElements) {
        if (this.searchMode) {
            this.markAssets(this.searchBar.getSearchPhrase());
            this.setActiveMark(this.markActive);
        }
    }
    assetRefreshed() {
        if (this.searchMode) {
            this.markAssets(this.searchBar.getSearchPhrase());
        }
    }
    async getSearchResults(phrase) {
        try {
            const { assignmentId, section } = this.editor;
            if (!section)
                return;
            this.searchResult = await searchAsset(assignmentId, section.id, phrase, 0, SEARCH_LIMIT);
            this.searchBar.setResults(this.searchResult.previousOccurrences, this.searchResult.totalOccurrences);
            trackSearch(assignmentId, phrase);
        }
        catch (e) {
            HTTPLogger.error("Editor - Failed to fetch search results", e);
        }
    }
    async search(phrase) {
        var _a;
        this.searchPhrase = phrase;
        this.clearAllMarks();
        if (phrase == "") {
            this.clearSearch();
            return;
        }
        await this.getSearchResults(phrase);
        this.markAssets(phrase);
        const order = this.findNearestAssetOccurenceOrder((_a = this.searchResult) === null || _a === void 0 ? void 0 : _a.results);
        order && this.setActiveMark({ order, position: 0 });
    }
    setActiveMark(activeMark) {
        if (activeMark.order === -1)
            return;
        this.searchBar.setButtonsDisabled(false);
        this.editor.content.querySelectorAll("mark.current").forEach(e => e.classList.remove("current"));
        const asset = this.editor.content
            .querySelector(`xfl-new-editor-asset[order="${activeMark.order}"]`);
        if (!asset)
            return;
        const marks = asset.querySelectorAll("mark");
        if (activeMark.position < 0)
            activeMark.position = 0;
        const current = marks[activeMark.position];
        if (!current)
            return;
        current.classList.add("current");
        this.editor.activeAsset = asset;
        this.markActive = activeMark;
        this.setOccurences(activeMark);
        asset.contentDiv.focus();
        window.requestAnimationFrame(() => asset.scrollIntoView({ block: "nearest", behavior: "smooth" }));
    }
    setOccurences(activeMark) {
        var _a;
        if (!this.searchResult)
            return;
        let occurrences = activeMark.position;
        this.searchResult.results
            .filter(r => r.location.assetOrder < activeMark.order)
            .forEach((r) => { occurrences += r.occurrences; });
        this.searchBar.setResults(occurrences, (_a = this.searchResult) === null || _a === void 0 ? void 0 : _a.totalOccurrences);
    }
    findNearestAssetOccurenceOrder(results) {
        var _a, _b, _c, _d;
        if (!results)
            return null;
        const activeAssetOrder = ((_a = this.editor.activeAsset) === null || _a === void 0 ? void 0 : _a.order) || -1;
        const order = ((_b = results.find(r => r.location.assetOrder >= activeAssetOrder)) === null || _b === void 0 ? void 0 : _b.location.assetOrder)
            || ((_c = results.reverse().find(r => r.location.assetOrder <= activeAssetOrder)) === null || _c === void 0 ? void 0 : _c.location.assetOrder)
            || null;
        if (order && !this.markMap.get(order)) {
            this.markActive.order = order;
            this.markActive.position = 0;
            (_d = this.editor.scroll) === null || _d === void 0 ? void 0 : _d.moveTo(order, this.editor.removeAllAssets.bind(this.editor), 'search');
        }
        return order;
    }
    markAssets(phrase) {
        this.clearAllMarks();
        this.markMap.clear();
        phrase && this.editor.content.querySelectorAll("xfl-new-editor-asset").forEach((a) => {
            this.highlightPhraseInAsset(a, phrase);
        });
    }
    clearSearch() {
        this.searchPhrase = "";
        this.markAssets("");
        this.editor.restoreSelectionRange();
    }
    clearSearchPhrase() {
        this.searchBar.clear();
    }
    getNodesToTextNodes(nodes) {
        return nodes.map((node) => {
            return (node instanceof SubAssetElement || node instanceof TerminologyElement)
                ? this.getNodesToTextNodes(Array.from(node.childNodes))
                : node instanceof UniversalTagElement
                    ? this.getNodesToTextNodes(Array.from(node.childNodes))
                    : node instanceof Text
                        ? node
                        : this.getNodesToTextNodes(Array.from(node.childNodes));
        })
            .flat(20)
            .filter(node => node instanceof Text);
    }
    highlightPhraseInAsset(asset, phrase) {
        if (asset.querySelectorAll("mark").length > 0)
            return;
        const textNodes = this.getNodesToTextNodes(asset.childrenWithoutCaretGuards().filter(node => !(node instanceof UniversalTagElement && node.macro === true)));
        const searchData = { text: textNodes.map(node => node.data).join('').toLocaleLowerCase(this.editor.content.lang), subnodePositions: textNodes.map(node => node.data.length) };
        const lowercasePhrase = phrase.toLocaleLowerCase(this.editor.content.lang);
        const children = textNodes;
        let lastIndex = searchData.text.lastIndexOf(lowercasePhrase);
        const marks = [];
        while (lastIndex >= 0) {
            const mark = this.markPhraseOccurrenceInAsset(asset, searchData, phrase, children, lastIndex);
            mark && marks.push(mark);
            lastIndex = lastIndex > 0 ? searchData.text.lastIndexOf(lowercasePhrase, lastIndex - 1) : -1;
        }
        marks.length > 0 && this.markMap.set(asset.order, marks);
    }
    markPhraseOccurrenceInAsset(asset, searchData, phrase, children, lastIndex) {
        var _a;
        let subnodeIndex = 0;
        let acc = 0;
        for (let index = 0; index <= searchData.subnodePositions.length; index++) {
            if (acc >= lastIndex) {
                subnodeIndex = index - 1 > 0 ? index - 1 : 0;
                break;
            }
            acc += searchData.subnodePositions[index];
        }
        let offset = 0;
        let mark;
        let indexInNode = lastIndex - searchData.subnodePositions.slice(0, subnodeIndex).reduce((a, b) => a + b, 0);
        while (offset < phrase.length) {
            mark = this.markPhraseFragmentInAsset(asset, children[subnodeIndex], indexInNode, phrase.length - offset, offset == 0);
            offset += ((_a = mark === null || mark === void 0 ? void 0 : mark.textContent) === null || _a === void 0 ? void 0 : _a.length) || 0;
            indexInNode = 0;
            subnodeIndex++;
        }
        return mark;
    }
    markPhraseFragmentInAsset(asset, textNode, index, length, phraseStart) {
        var _a, _b;
        textNode.splitText(Math.min(index + length, (_b = (_a = textNode.textContent) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0));
        const nodeToReplace = textNode.splitText(index);
        const mark = document.createElement("mark");
        mark.toggleAttribute("data-phrase-start", phraseStart);
        if (!nodeToReplace.textContent)
            return null;
        mark.textContent = nodeToReplace.textContent;
        nodeToReplace.replaceWith(mark);
        asset.contentDiv.normalize();
        return mark;
    }
    clearPhraseInAsset(asset) {
        if (!asset)
            return;
        this.markMap.delete(asset.order);
        asset.contentDiv.querySelectorAll("mark")
            .forEach(e => { var _a; return e.replaceWith(document.createTextNode((_a = e.textContent) !== null && _a !== void 0 ? _a : "")); });
        asset.contentDiv.normalize();
    }
    getCurrentMark() {
        return this.editor.content.querySelector("mark.current");
    }
    clearAllMarks() {
        const marks = Array.from(this.editor.content.querySelectorAll("mark"));
        marks.forEach(m => this.clearPhraseInAsset(ancestorOfType(m, 'xfl-new-editor-asset')));
    }
    /**
     * Move To next search result
     *
     * Depending on the offset parameter
     * decide if we should move using forward
     * or backward method
     * @param offset
     */
    moveSearchCurrentMark(offset) {
        const direction = offset >= 0 ? 'forward' : 'backward';
        this.searchBar.setButtonsDisabled(true);
        direction === 'forward'
            ? this.moveForward()
            : this.moveBackward();
        this.setActiveMark(this.markActive);
    }
    moveForward() {
        var _a;
        try {
            this.findNextMarkOccurence(this.markActive, this.markMap, ((_a = this.searchResult) === null || _a === void 0 ? void 0 : _a.results) || [], this.jumpToNextOrder.bind(this));
        }
        catch (e) {
            HTTPLogger.warn(e.message);
        }
    }
    moveBackward() {
        var _a;
        try {
            this.findPrevMarkOccurence(this.markActive, this.markMap, ((_a = this.searchResult) === null || _a === void 0 ? void 0 : _a.results) || [], this.jumpToNextOrder.bind(this));
        }
        catch (e) {
            HTTPLogger.warn(e.message);
        }
    }
    /**
     * Find Next Mark Occurence
     *
     * Complete logic to find next result occurance
     * This method update current mark based on the
     * backend searchResult property. If next asset
     * is not visible on Editor (visibleResults)
     * invoke onNotFound method
     *
     * @param currentMark - Current result position ActiveMark
     * @param visibleResults - Currently visible marks
     * @param searchResults - Reference array from backend (contain all elements)
     * @param onNotFound - Callback invoked when next mark is out of visible scope
     */
    findNextMarkOccurence(currentMark, visibleResults, searchResults, onNotFound) {
        var _a;
        const phraseOccurences = ((_a = searchResults.find(({ location }) => location.assetOrder === currentMark.order)) === null || _a === void 0 ? void 0 : _a.occurrences) || 1;
        if (currentMark.position >= phraseOccurences - 1) {
            const nextAsset = searchResults.filter(({ location }) => location.assetOrder > currentMark.order);
            if (nextAsset.length === 0) {
                throw new Error("There is no further results");
            }
            const nextOrder = nextAsset[0].location.assetOrder;
            currentMark.position = 0;
            currentMark.order = nextOrder;
            if (!visibleResults.get(nextOrder)) {
                onNotFound(nextOrder);
            }
        }
        else {
            currentMark.position = currentMark.position + 1;
        }
    }
    /**
     * Find Previous Mark Occurence
     *
     * @param currentMark - Current result position ActiveMark
     * @param visibleResults - Currently visible marks
     * @param searchResults - Reference array from backend (contain all elements)
     * @param onNotFound - Callback invoked when next mark is out of visible scope
     */
    findPrevMarkOccurence(currentMark, visibleResults, searchResults, onNotFound) {
        if (currentMark.position <= 0) {
            const [previousResult] = searchResults.filter(({ location }) => location.assetOrder < currentMark.order).reverse();
            if (!previousResult) {
                throw new Error("There is no previous results");
            }
            currentMark.position = previousResult.occurrences - 1;
            currentMark.order = previousResult.location.assetOrder;
            if (!visibleResults.get(previousResult.location.assetOrder)) {
                onNotFound(previousResult.location.assetOrder);
            }
        }
        else {
            currentMark.position = currentMark.position - 1;
        }
    }
    jumpToNextOrder(nextOrder) {
        var _a;
        (_a = this.editor.scroll) === null || _a === void 0 ? void 0 : _a.moveTo(nextOrder, () => {
            this.markMap.clear();
            this.editor.removeAllAssets();
        }, 'search');
    }
    keyDown(event) {
        switch (event.key) {
            case "f":
                if (event.ctrlKey || event.metaKey) {
                    this.searchMode = true;
                    consumeEvent(event);
                }
                break;
        }
    }
    set searchMode(searchMode) {
        this.editor.header.toggleAttribute("searchMode", searchMode);
        this.editor.header.toggleHeaderSection();
        this.searchButton.selected = searchMode;
        this.searchBar.input.focus();
        if (!searchMode)
            this.clearSearchPhrase();
    }
    get searchMode() {
        return this.editor.header.hasAttribute("searchMode");
    }
    get marks() {
        return Array.from(this.editor.content.querySelectorAll("mark[data-phrase-start]"));
    }
}
