import i18next from "i18next";
import { consumeEvent } from 'editorNextGen/utils';
import { arrowUpSearchSvg } from "editorNextGen/icons/arrowUpSearch";
import { IconNgBuilder } from "editorNextGen/icons";
import { arrowDownSearchSvg } from "editorNextGen/icons/arrowDownSearch";
import { cancelSvg } from "editorNextGen/icons/cancel";
const INPUT_TIMEOUT = 2000;
export default class SearchBar extends HTMLElement {
    constructor(listener) {
        super();
        this.searchTimeout = null;
        this.listener = listener;
        this.searchIndex = 0;
        this.searchTotal = 0;
        this.searchPhrase = "";
        this.input = document.createElement("input");
        this.input.placeholder = i18next.t("editorHeader.search");
        this.input.type = "text";
        this.input.className = "searchInput";
        this.appendChild(this.input);
        const navigationSection = document.createElement("div");
        navigationSection.className = "flex align-center";
        this.label = document.createElement("span");
        this.label.className = "hint";
        navigationSection.appendChild(this.label);
        new IconNgBuilder(cancelSvg, navigationSection)
            .setOnClick(() => { this.clear(); }).build();
        this.previousButton = new IconNgBuilder(arrowUpSearchSvg, navigationSection)
            .setIconFill('stroke').setOnClick(() => this.onMoveSearchMark(-1)).build();
        this.nextButton = new IconNgBuilder(arrowDownSearchSvg, navigationSection)
            .setIconFill('stroke').setOnClick(() => this.onMoveSearchMark(1)).build();
        this.appendChild(navigationSection);
    }
    connectedCallback() {
        this.input.type = "text";
        this.input.addEventListener("input", this.onInput.bind(this));
        this.addEventListener("keydown", this.onKeyDown.bind(this));
    }
    clear() {
        this.searchPhrase = "";
        this.input.value = this.searchPhrase;
        this.listener.clearSearch();
        this.update();
    }
    setResults(currentIndex, totalResults) {
        this.searchIndex = Math.min(currentIndex + 1, totalResults);
        this.searchTotal = totalResults;
        this.update();
    }
    getSearchPhrase() {
        return this.searchPhrase;
    }
    setButtonsDisabled(disabled) {
        this.nextButton.disabled = disabled;
        this.previousButton.disabled = disabled;
    }
    onInput() {
        if (this.searchTimeout !== null) {
            window.clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = window.setTimeout(() => {
            this.searchPhrase = this.removeZeroWidthChars(this.input.value);
            !this.searchPhrase && this.update();
            this.listener.search(this.searchPhrase);
            this.searchTimeout = null;
        }, INPUT_TIMEOUT);
    }
    removeZeroWidthChars(text) {
        return text.replace(/\u200B|\u200C|\u200D|\u2060|\uFEFF/g, "");
    }
    onKeyDown(event) {
        switch (event.key) {
            case "Escape":
                this.clear();
                consumeEvent(event);
                event.target.blur();
                return false;
            default:
                if ((event.ctrlKey || event.metaKey) && event.key == 'f') {
                    consumeEvent(event); // Prevent browsers search bar kick in
                }
                return true;
        }
    }
    onMoveSearchMark(offset) {
        if (this.searchIndex + offset < 1 || this.searchIndex + offset > this.searchTotal)
            return;
        this.searchIndex += offset;
        this.listener.moveSearchCurrentMark(offset);
        this.update();
    }
    update() {
        this.label.textContent = !this.searchPhrase
            ? ''
            : `${this.searchIndex} / ${this.searchTotal}`;
    }
}
document.addEventListener("DOMContentLoaded", () => customElements.define("xfl-new-editor-search-bar", SearchBar));
